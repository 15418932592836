body, .full-page-wrapper {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  background: linear-gradient(220deg, #f8f8f5 20%, #eff7f2 30% 60%, #bfcec5);
  color: rgb(15, 3, 3);
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.assessment-container {
  padding: 20px;
  width: 95%;
  max-width: 100%; /* Adjust width as necessary */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: auto;
  }
  
  .question-container {
    border: 1px solid #fff;
    padding: 20px;
    margin-bottom: 20px;
    transition: all 0.5s;
  }
  
  .time-critical {
    animation: flashRed 0.5s infinite;
  }
  
  @keyframes flashRed {
    0%, 100% { background-color: transparent; }
    50% { color: red; font-weight: 100; }
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  li {
    background: #fff;
    color: #333;
    margin: 10px 0;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .splash-screen, .thank-you-screen {
    text-align: center;
    padding: 50px;
    background: #fff;
    color: #333;
  }
  
  button {
    background: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .assessment-input[type="text"] {
    width: 100%;
    padding:12px 20px;
    margin: 10px 0;
    box-sizing: border-box;
    border: 2px solid #ccc; /* Makes the border thicker and more noticeable */
  border-radius: 4px; /* Rounds the corners of the input box */
  font-size: 16px; /* Increases the font size for better readability */
  height:max-content;
  }
  li.selected {
    background-color: #4CAF50; /* Green */
    color: white;
  }
  .assessment-textarea {
    width: 100%;       /* Makes the textarea take the full width of its container */
    height: 150px;     /* Sets a fixed height for the textarea */
    padding: 12px 20px; /* Increases padding for better readability and easier typing */
    box-sizing: border-box; /* Includes padding and border in the element's total width and height */
    border: 2px solid #ccc; /* Makes the border thicker and more noticeable */
    border-radius: 4px; /* Rounds the corners of the textarea */
    font-size: 16px; /* Increases the font size for better readability */
    resize: vertical; /* Allows the user to resize the textarea vertically */
  }
  .full-width-image {
    width: 100%;
    height: 300px; /* Default height for larger screens */
    object-fit: cover;
  }
  
  @media (max-width: 768px) { /* Adjust height for smaller screens */
    .full-width-image {
      height: 200px;
    }
  }
  
  @media (max-width: 480px) { /* Adjust height for mobile screens */
    .full-width-image {
      height: 150px;
    }
  }
  .question-info {
    text-align: right;
    flex-grow: 1;
  }  
  
.time-left h1, .question-info h2 {
  margin: 0;
}

.time-left {
  flex-grow: 1;
}
.assessment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}