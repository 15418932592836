.create-assessment-form {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .create-assessment-form h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .form-group textarea {
    resize: vertical;
    height: 100px;
  }
  
  .questions-section {
    margin-top: 30px;
  }
  
  .questions-section h3 {
    margin-bottom: 20px;
    font-size: 20px;
    color: #333;
    border-bottom: 2px solid #eee;
    padding-bottom: 5px;
  }
  
  .question-block {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .question-block .form-group {
    margin-bottom: 10px;
  }
  
  .options-group {
    margin-top: 10px;
  }
  
  .options-group input {
    margin-bottom: 5px;
  }
  
  .add-question-btn,
  .remove-question-btn,
  .submit-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .add-question-btn:hover,
  .remove-question-btn:hover,
  .submit-btn:hover {
    background-color: #0056b3;
  }
  
  .remove-question-btn {
    margin-top: 10px;
    background-color: #dc3545;
  }
  
  .remove-question-btn:hover {
    background-color: #c82333;
  }
  
  .submit-btn {
    display: block;
    width: 100%;
    margin-top: 30px;
  }
  